import ModalController from "./modal_controller.js"
import { Dropdown } from "@app/admin/dropdown.coffee"
import '@app/file_attachment_drop_zone.js'

export default class extends ModalController {

  static targets = [
    'form',
    'helpWith',
    'extraField',
    'extraIntegrationField',
    'integration',
    'error',
    'submit'
  ]

  connect() {
    this.extraFieldTargets.forEach(target => target.disabled = true)
    this.extraIntegrationFieldTargets.forEach(target => target.disabled = true)
    this.chromeTooltipFix()

    this.setupSelect2Listeners()
    this.setupDropzoneListeners()

    super.connect()
  }

  modalShown() {
    BigSpinner.disableOnce()
    $.get($(this.element).data("onLoadUrl"))
  }

  modalHidden() {
    this.resetForm()
  }

  submitForm(event) {
    this.errorTarget.classList.add("d-none")
    this.errorTarget.innerHTML = ''

    let file = this.dropzoneUploader.file

    if (!this.validateForm()) {
      event.preventDefault()
      this.errorTarget.classList.remove("d-none")
      this.errorTarget.innerHTML = 'Please Complete All Fields.'
      this.submitTarget.classList.remove('d-none')
      this.submitTarget.removeAttribute('disabled')
      $(this.element).scrollTop(0)
    }
    else if(file) {
      let ext = file.name.match(/\w\w\w$/)
      if(!(ext && ["jpg", "png", "pdf", "csv", "lsx"].includes(ext[0]))) {
        event.preventDefault()
        this.errorTarget.classList.remove("d-none")
        this.errorTarget.innerHTML = 'File must be JPG, PDF, PNG, CSV or XLSX.'
        this.submitTarget.classList.remove('d-none')
        this.submitTarget.removeAttribute('disabled')
        $(this.element).scrollTop(0)
      } else if(file.size > 5e+7) {
        event.preventDefault()
        this.errorTarget.classList.remove("d-none")
        this.errorTarget.innerHTML = 'File size cannot exceed 50MB.'
        this.submitTarget.classList.remove('d-none')
        this.submitTarget.removeAttribute('disabled')
        $(this.element).scrollTop(0)
      } else {
        this.submitFormWithFile()
        event.preventDefault()
      }
    }
    else {
      this.submitTarget.setAttribute("disabled", "true");
      this.submitTarget.value = "Submitting...";
      this.submitFormWithFile()
      event.preventDefault()
    }
  }

  submitFormWithFile() {
    let xhr = new XMLHttpRequest()
    xhr.open('POST', this.formTarget.action)
    xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))

    xhr.onload = (event) => {
      let response = event.currentTarget.response
      this.submitTarget.classList.remove("disabled")
      this.submitTarget.value = "Submit Request"
      new Function(response)()
    }

    let formData = new FormData($("#zendesk-ticket-form")[0])

    this.submitTarget.value = "Submitting..."
    xhr.send(formData)
    this.submitTarget.classList.add("disabled")
  }

  validateForm() {
    let isValid = true

    this.requiredFields().forEach((field) => {
      if ($(field).is(":visible") && !field.disabled && !field.value.trim()) {
        field.classList.add('blank')
        isValid = false
      } else {
        field.classList.remove('blank')
      }
    })

    return isValid
  }

  requiredFields() {
    const selectors = 'textarea:required, input:required, select:required'
    return this.formTarget.querySelectorAll(selectors)
  }

  resetForm() {
    this.errorTarget.classList.add("d-none")
    this.formTarget.reset()
    Dropdown.destroySelect2(this.helpWithTarget)
    this.requiredFields().forEach(element => element.classList.remove('blank'))
    this.helpWithChanged()
    this.removeFileDropzone()
  }

  helpWithChanged() {
    Dropdown.destroySelect2(this.integrationTarget)
    this.integrationTarget.value = ''
    this.integrationChanged()
    const newHelpWith = this.helpWithTarget.value
    this.extraFieldTargets.forEach((target) => {
      const fields = JSON.parse(target.dataset.extraFieldFor)
      if(fields.includes(newHelpWith)) {
        target.closest(".extra-field-group").classList.remove('d-none')
        target.closest(".form-group").classList.remove('d-none')
        target.removeAttribute('disabled')
      } else {
        target.closest(".extra-field-group").classList.add('d-none')
        target.closest(".form-group").classList.add('d-none')
        target.disabled = true
      }
    })
    if (newHelpWith) {
      document.getElementById('zendesk-ticket-form__extra-fields')?.classList.remove('d-none')
    } else {
      document.getElementById('zendesk-ticket-form__extra-fields')?.classList.add('d-none')
    }
    $(document).trigger('initDropdowns')
  }

  integrationChanged() {
    const newIntegration = this.integrationTarget.value
    this.extraIntegrationFieldTargets.forEach((target) => {
      const fields = JSON.parse(target.dataset.extraIntegrationFieldFor)
      if(fields.includes(newIntegration)) {
        target.closest(".form-group").classList.remove('d-none')
        target.removeAttribute('disabled')
      } else {
        target.closest(".form-group").classList.add('d-none')
        target.disabled = true
      }
    })
  }

  dragoverDropzone() {
    new Momd.FileAttachmentDropZone('#support-ticket-modal .modal-body')
  }

  dragleaveDropzone() {
    $('#support-ticket-modal .modal-body').removeClass('in hover');
  }

  dropDropzone() {
    $('#support-ticket-modal .modal-body').removeClass('in hover');
  }

  removeFileDropzone() {
    $(this.element).find(".file_attachment").removeClass('d-none')
    $(this.element).find(".attachment__summary").addClass('d-none')
    $(this.element).find("input[name=files]")[0].value = null
    $(this.element).find("input[name=files]")[1].value = null
    $(this.element).find("#file-type-icon").removeClass()
  }

  addFileDropzone(file) {
    $(this.element).find(".file-size").html(file.size + " bytes")
    $(this.element).find(".content-type").html(file.type)
    $(this.element).find(".file-name").html(file.name)
    $(this.element).find(".file_attachment").addClass('d-none')
    $(this.element).find(".attachment__summary").removeClass('d-none')
    $(this.element).find("#file-type-icon").addClass(file.type)
  }

  setupDropzoneListeners() {
    this.dropzoneUploader = new Momd.ModalAttachmentUploader({
      skipXhr: true,
      attachmentFieldSelector: "#support-ticket-modal .attachment-field",
      onAttach: (file) => {
        this.addFileDropzone(file)
      }
    });

    this.dropzoneUploader.setupHandlers();
  }

  setupSelect2Listeners() {
    // TODO: Wire up to select2 controller once it's merged and remove this function.
    // Capturing select2 event and sending it back out as a standard change event
    $(this.helpWithTarget).on('select2:select', function () {
      let event = new Event('change')
      this.dispatchEvent(event)
    })

    // Capturing select2 event and sending it back out as a standard change event
    $(this.integrationTarget).on('select2:select', function () {
      let event = new Event('change')
      this.dispatchEvent(event)
    })
  }

  chromeTooltipFix() {
    // Hide the "Please Fill out This Field" tooltip on Chrome that shows up on textarea fields only
    this.formTarget.querySelectorAll('textarea').forEach((field) => {
      field.setAttribute('title', '')
    })
  }
}
