import { get } from '@rails/request.js'
import { Controller } from "@hotwired/stimulus"
import hljs from 'highlight.js/lib/core'

export default class extends Controller {
  static targets = ["header", "headerContent", "loader", "workItems", "workItemsContent", "coveragesEstimations",
    "coveragesContent", "estimationsContent"]

  static sections = ["headerContent", "workItems", "coveragesEstimations", "estimationsContent"]

  initialize() {
    this.pendingSections = this.constructor.sections
  }

  connect() {
    this.currentPath = window.location.pathname
    this.oauthToken = window.availityOauthToken.innerHTML
    get(this.currentPath + "/coverage_summary", { query: { oauth_token: this.oauthToken }, responseKind: "turbo-stream" })
    get(this.currentPath + "/visit", { query: { oauth_token: this.oauthToken }, responseKind: "turbo-stream" })
    get(this.currentPath + "/estimations", { query: { oauth_token: this.oauthToken }, responseKind: "turbo-stream" })
    get(this.currentPath + "/coverages", { query: { oauth_token: this.oauthToken }, responseKind: "turbo-stream" })
    get(this.currentPath + "/work_items", { query: { oauth_token: this.oauthToken }, responseKind: "turbo-stream" })
    get(this.currentPath + "/expected_benefits", { query: { oauth_token: this.oauthToken }, responseKind: "turbo-stream" })
    this.subscribeToVeriproChannel()
  }

  showAll() {
    if(this.pendingSections.length == 0) {
      this.headerTarget.classList.remove("d-none")
      this.workItemsTarget.classList.remove("d-none")
      this.estimationsContentTarget.classList.remove("d-none")
      this.coveragesEstimationsTarget.classList.remove("d-none")
      this.loaderTarget.classList.add("d-none")
    }
  }

  headerContentTargetConnected() {
    const index = this.pendingSections.indexOf("headerContent");
    if (index > -1) {
      this.pendingSections.splice(index, 1);
    }
    this.showAll()
  }

  workItemsTargetConnected() {
    const index = this.pendingSections.indexOf("workItems");
    if (index > -1) {
      this.pendingSections.splice(index, 1);
    }
    this.showAll()
  }

  coveragesEstimationsTargetConnected() {
    const index = this.pendingSections.indexOf("coveragesEstimations");
    if (index > -1) {
      this.pendingSections.splice(index, 1);
    }
    this.showAll()
  }

  estimationsContentTargetConnected() {
    const index = this.pendingSections.indexOf("estimationsContent");
    if (index > -1) {
      this.pendingSections.splice(index, 1);
    }
    this.showAll()
  }

  highlightCode() {
    hljs.highlightAll()
	}

  subscribeToVeriproChannel() {
    let pusher = window.getPusher()
    let patientAgreementId = $("#patientAgreementId").text()
    let paymentMethodId = $("#paymentMethodId").text()
    let channel = pusher.subscribe('private-patient-agreement-'+patientAgreementId+'-channel')

    let callback = () => {
      get(this.currentPath + "/coverage_summary", { query: { oauth_token: this.oauthToken }, responseKind: "turbo-stream" })
      get(this.currentPath + "/estimations", { query: { oauth_token: this.oauthToken }, responseKind: "turbo-stream" })
    }

    let channelCallback = (data) => {
      if(data.patient_agreement_id.toString() == patientAgreementId && data.payment_method_id.toString() == paymentMethodId) {
        callback()
        pusher.unsubscribe('private-patient-agreement-'+patientAgreementId+'-channel')
      }
    }

    channel.bind('coverage-complete', channelCallback)
  }
}
