import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ['form', 'qrCode', 'multiPageType', 'multiPageBtn']

  static values = {
    refreshCardUrl: String
  }

  connect() {
    this.lastTimerValue = Date.now();
    this.interval = setInterval(() => {
      let newTimerValue = Date.now();
      if(newTimerValue - this.lastTimerValue > 2000) {
        this.checkPersisted()
      }
      this.lastTimerValue = newTimerValue;
    }, 300)
  }

  disconnect() {
    clearInterval(this.interval);
  }

  submitForm(event){
    event.target.form.requestSubmit()
  }

  updateMultiPage(event){
    let $newMultiPageUrl = $(this.multiPageTypeTarget).find(':selected').data("multipageUploadUrl").replace(/\.js($|\?)/, '$1')
    $(this.multiPageBtnTarget).attr("href", $newMultiPageUrl)
    $(this.multiPageBtnTarget).removeClass('disabled')
  }

  userAgentIsIpadEtc() {
    let ua = window.navigator.userAgent.toLowerCase()
    return /iPhone|iPad|iPod/i.test(navigator.userAgent) || (ua.indexOf('ipad') > -1 || ua.indexOf('macintosh') > -1 && 'ontouchend' in document)
  }

  checkPersisted() {
    let { checkPersistedUrl } = this.qrCodeTarget.dataset
    $.get(checkPersistedUrl, (data) => {
      if (data.persisted) {
        this.attachmentReceivedCallback()
        return true;
      }
    });
  }

  async attachmentReceivedCallback() {
    await get(this.refreshCardUrlValue, {
      responseKind: "turbo-stream"
    })
  }

  qrCodeTargetConnected(target) {
    let { companionAppUrl, token, paPageUrl, checkPersistedUrl } = target.dataset

    var pusher = getPusher();
    var channel = pusher.subscribe('private-attachment-channel');

    channel.bind('attachment-received', (recvToken) => {
      if(token === recvToken) {
        this.attachmentReceivedCallback()
      }
    });

    if(this.userAgentIsIpadEtc()){
      window.location = companionAppUrl
    }
  }
}
