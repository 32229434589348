import { Controller } from "@hotwired/stimulus"
import { patch } from "@rails/request.js"

export default class extends Controller {
  static targets = ['warning']

  static values = { 
    warningsCount: Number,
    warningType: String,
    warningTypeSection: String
  }

  connect() {}

  warningTargetConnected() {
    requestAnimationFrame(() => {
      this.initializePopover()
      this.updateStateAndOpenWarning()
    })
  }

  initializePopover() {
    this.content_limit = document.getElementById(this.element.dataset['boundary']) || 'none'
    
    let element = document.getElementsByClassName(this.element.classList.value)[0]

    this.popover = $(element).popover({
      boundary: this.content_limit,
      offset: '100, 0'
    })

    this.manualTrigger()
  }

  manualTrigger() {
    $(this.element).on('click', '.popover', (event) => {
      event.stopPropagation();
    });

    $(this.element).on('shown.bs.popover', () => {
      this.element.querySelector('.close').addEventListener('click', (event) => {
        $(document).trigger('patient_agreement.state', function(patientAgreement) {        
          patientAgreement.warningsStatus[this.warningTypeValue]['status'] = 'closed'
        }.bind(this))

        $(this.element).popover('hide')
      })

      const CustomFitFormWarningService = this.element.querySelector('#CustomFitFormWarningService');
      if(CustomFitFormWarningService) {
        CustomFitFormWarningService.dataset.turbo = true
        CustomFitFormWarningService.dataset.turboStream = true
        CustomFitFormWarningService.dataset.action = 'modal-links#open'
        CustomFitFormWarningService.dataset.modal = '#attachments-select-custom-form-modal'
      }

      const CustomFitFormWarningMedicareService = this.element.querySelector('#CustomFitFormWarningMedicareService');
      if(CustomFitFormWarningMedicareService) {
        const hipCustomFitForm = $("#attachments-select-custom-form-modal").find("#hip-custom-fit-form")[0]

        CustomFitFormWarningMedicareService.addEventListener('click', (event) => {
          if (hipCustomFitForm) {
            hipCustomFitForm.click()
          } else {
            $('#custom-form-not-found-modal').modal('show')
          }          
        })
      }

      const FittingAdjustmentFormWarningService = this.element.querySelector('#FittingAdjustmentFormWarningService');
      if(FittingAdjustmentFormWarningService) {
        FittingAdjustmentFormWarningService.dataset.turbo = true
        FittingAdjustmentFormWarningService.dataset.turboStream = true
        FittingAdjustmentFormWarningService.dataset.action = 'modal-links#open'
        FittingAdjustmentFormWarningService.dataset.modal = '#attachments-select-custom-form-modal'
      }

      const JumpBallHcpcsCodesService = this.element.querySelector('#JumpBallHcpcsCodesService');
      if(JumpBallHcpcsCodesService) {
        const patient_agreement_id = document.body.dataset.patientAgreementId
        const patient_agreement_product_id = this.element.dataset.ewResourceId
        const params = JSON.stringify({ patient_agreement_id: patient_agreement_id, patient_agreement_product: { fulfill_from: "stock", billing_channel_id: 1 } })
        
        JumpBallHcpcsCodesService.addEventListener('click', (event) => {
          JumpBallHcpcsCodesService.classList.add('disabled')

          BigSpinner.enableOnce()
          BigSpinner.add()
          patch(`/admin/patient_agreements/patient_agreement_products/${patient_agreement_product_id}`, {
            body: params,
            contentType: "application/json",
            responseKind: "turbo-stream"
          }).then(response => {
            BigSpinner.finished()
          });
        })
      }
    })
  }

  openWarning() {
    if (!this.element.classList.contains('d-none')) {
      $(document).trigger('patient_agreement.state', function(patientAgreement) {        
        patientAgreement.warningsStatus[this.warningTypeValue]['status'] = 'open'
      }.bind(this))

      this.popover.popover('show')
    }
  }

  updateStateAndOpenWarning() {
    $(document).trigger('patient_agreement.state', function(patientAgreement) {

      patientAgreement.warningsStatus[this.warningTypeValue] = patientAgreement.warningsStatus[this.warningTypeValue] || {};

      if (patientAgreement.warningsStatus[this.warningTypeValue]['count'] !== this.warningsCountValue ||
          patientAgreement.warningsStatus[this.warningTypeValue]['status'] == 'open') {
        this.openWarning()
      }
      
      patientAgreement.warningsStatus[this.warningTypeValue]['count'] = this.warningsCountValue
    }.bind(this))
  }

  openWarningsSection() {
    const sectionID = this.warningTypeSectionValue + '_section'
    const section = document.getElementById(sectionID);
    
    if (section) {
      const popoverControllers = section.querySelectorAll('[data-controller="ew-popover"]');

      popoverControllers.forEach((element) => {
        const controllerInstance = this.application.getControllerForElementAndIdentifier(element, 'ew-popover');

        if (controllerInstance && typeof controllerInstance.openWarning === 'function') {
          controllerInstance.openWarning();
        }
      });
    }
  }
}
